


















import { Component, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
import { namespace } from "vuex-class";
import RouterViewLayer from "@/RouterViewLayer.vue";

const SnackbarModule = namespace("snackbar");
const ModalModule = namespace("modal");
const CommunicationModule = namespace("communication");

@Component({
  components: { RouterViewLayer, Navbar, Sidebar },
})
export default class Home extends Vue {
  public name = "Dashboard";
  public tabs: Array<number> = [];
  public tabCounter = 0;
  protected pdfModalId = "pdf-modal";
  protected srcPdf = null;

  @SnackbarModule.Getter("getMessage")
  private snackbarMessage: any;

  @SnackbarModule.Action("resetSnackbar")
  private resetSnackbar: any;

  @ModalModule.Action("setStatus")
  private setModalStatus: any;

  @ModalModule.Action("setContent")
  private setModalContent: any;

  @ModalModule.Getter("getStatus")
  private modalStatus: any;

  @ModalModule.Getter("getContent")
  private modalContent: any;

  @CommunicationModule.Action("notifications")
  public notifications!: () => void;

  protected wrapperWidth = "wrapper size-full-screen";

  @Watch("snackbarMessage")
  private onSnackbarStatus(snackbarMessage: any): void {
    if (snackbarMessage) {
      switch (snackbarMessage.type) {
        case "success":
          this.$toasted.success(snackbarMessage.text);
          break;
        case "error":
          this.$toasted.error(snackbarMessage.text);
          break;
        default:
          this.$toasted.info(snackbarMessage.text);
          break;
      }
      this.resetSnackbar();
    }
  }

  @Watch("modalStatus")
  protected getStatusWatcher(status: boolean): void {
    if (status) {
      this.$bvModal.show(this.pdfModalId);
    }
  }

  protected onHideModal(): void {
    this.setModalStatus(false);
    this.setModalContent(null);
  }

  public mounted(): void {
    this.notifications();
  }

  public sidebarSizeChange(hideNames: any) {
    if (hideNames) {
      this.wrapperWidth = "wrapper size-full-screen";
    } else {
      this.wrapperWidth = "wrapper-narrow size-full-screen";
    }
  }

  public sidebarShow() {
    this.wrapperWidth = "wrapper size-full-screen";
  }
}
