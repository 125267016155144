






































import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import type { ISidebar } from "@/interfaces/ISidebar";
import { RouteRecord } from "vue-router/types/router";

@Component({
  components: {},
})
export default class SidebarItem extends Vue {
  public name = "SidebarItem";

  @Prop()
  public item!: ISidebar;

  @Prop()
  public activeItem!: ISidebar;

  @Prop()
  public hideItemName!: any;

  @Inject("activeMenuHandler")
  public activeMenuHandler!: any;

  public isActive = false;
  public iconSize = "lg";
  protected iconPrefix = "fat";

  public hasChildren(item: ISidebar): boolean {
    return !!item.children;
  }

  public toggleMenu(item: ISidebar): void {
    this.activeMenuHandler(item);
    this.isActive = !this.isActive;
  }

  @Watch("activeItem")
  public activeItemWatcher(val: ISidebar): void {
    if (val !== this.item) {
      this.isActive = false;
    }
  }

  public mounted(): void {
    this.isActive = this.matchRoute(this.item);
  }

  public matchRoute(item: ISidebar): boolean {
    if (!item.children) return false;
    return this.isChildrenActive(item.children);
  }

  public isChildrenActive(children: Array<ISidebar>) {
    if (!children) return false;
    return children.some((item: ISidebar) => {
      return this.isLinkActive(item);
    });
  }

  public isLinkActive(item: ISidebar): boolean {
    return this.isCurrentPath(item.path) || this.isCurrentAlias(item);
  }

  public get currentMatched(): RouteRecord {
    return this.$route.matched[1];
  }

  public isCurrentAlias(item: any): boolean {
    if (!item.alias) return false;
    return item.alias.some((item: any) => {
      return item == this.currentMatched?.path;
    });
  }

  public isCurrentPath(path: string): boolean {
    return path === this.currentMatched?.path;
  }

  protected mouseOver(): void {
    this.iconPrefix = "fas";
  }

  protected mouseLeave(): void {
    this.iconPrefix = "fat";
  }

  protected mouseClick(): void {
    this.$emit("mouse-click");
  }

  @Watch("$route.fullPath")
  protected onChangePath(): void {
    this.isActive = this.matchRoute(this.item);
  }
}
