




import { Component, Prop, Vue } from "vue-property-decorator";
import AuthImage from "@/directives/AuthImage";
import instructorPlaceholder from "@/assets/teacherimgplaceholder.png";

@Component({
  directives: {
    AuthImage: new AuthImage(),
  },
})
export default class AvatarInstructor extends Vue {
  @Prop({ type: Number, default: 0 })
  public userId!: number;

  get avatarUrl(): string {
    const USER_URL = "users";
    return `${USER_URL}/avatar?userId=${this.userId}`;
  }

  handleError(event: Event): any {
    (event.target as HTMLImageElement).src = instructorPlaceholder;
    (event.target as HTMLImageElement).alt = "Profile";
  }
}
