<template>
  <div class="d-flex flex-column h-100">
    <router-view :key="keyUid" :routerKey="vNodeKey" />
  </div>
</template>

<script>
export default {
  name: "RouterViewLayer",
  data: () => ({
    keyUid: 1,
  }),
  created() {
    this.$on("reload-page", () => {
      this.keyUid++;
    });
  },
  computed: {
    vNodeKey: function () {
      return this.$vnode.key;
    },
  },
};
</script>
